import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { Customers } from 'county-api';
import { PersonOccupationStatus } from 'county-api/enums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { JobsService } from '../../jobs/jobs.service';

/**
 * The add details person component.
 */
@Component({
    selector: 'person-add-details',
    templateUrl: './person-add-details.component.html',
})
export class PersonAddDetailsComponent implements OnDestroy {
    /**
     * The person data, input from the parent.
     */
    @Input()
    person: Customers.IPerson;

    /**
     * Determines whether the employee is defined, input from the parent.
     */
    @Input()
    employee: boolean;

    /**
     * Allow the disabling of the users name.
     */
    @Input()
    enableNameEditing: boolean;

    /**
     * onchange callback, fires the method back to the parent when changed.
     */
    @Output()
    personChange: EventEmitter<any> = new EventEmitter();

    /**
     * Occupations enum used within the template.
     */
    occupationStatuses = PersonOccupationStatus;

    /**
     * Subject used to track changes to the occupation, this allows for a
     * debounce search.
     */
    searchTerm$ = new BehaviorSubject<string>('');

    /**
     * Observable clean up.
     */
    unsubscribe$ = new Subject();

    /**
     * Observable that has the latest set of jobs to use in suggestions.
     */
    filteredJobs: Observable<Customers.ICustomerJob[]>;

    /**
     * Contructs injected angular dependencies ans set up filtered job
     * observable.
     * @param jobsService The jobs service, to get the filtered jobs for the
     * occupation input.
     */
    constructor(private jobsService: JobsService) {
        this.filteredJobs = this.searchTerm$.pipe(
            debounceTime(500),
            switchMap(term => {
                return this.jobsService.get({
                    page: 1,
                    perpage: 10,
                    filter: term,
                });
            }),
            map(response => {
                return response.result.jobs;
            }),
            takeUntil(this.unsubscribe$),
        );
    }

    /**
     * Call back to add the latest occupation status to the subject.
     */
    updateOccupation(term: string) {
        this.searchTerm$.next(term);
    }

    /**
     * Passes the input value to the parent when changed.
     */
    onNameInput() {
        console.log("name input event");
        this.personChange.emit();
    }

    /**
     * Clean up the component.
     */
    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
