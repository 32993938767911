<ul class="form" provideParentForm>
    <li class="form__item">
        <label class="input-label" for="person_forename">Forename</label>
        <input
            type="text"
            name="person_forename"
            id="person_forename"
            required
            [(ngModel)]="person.forename"
            titlecase
            (input)="onNameInput()"
            [disabled]="enableNameEditing == false"
            #personForename="ngModel"
        />
        <div class="input-errors" [ngxErrors]="personForename">
            <label
                for="person_forename"
                ngxError="required"
                class="input-error"
            >
                Please enter the forename.
            </label>
        </div>
    </li>

    <li class="form__item">
        <label class="input-label" for="person_surname">Surname</label>
        <input
            type="text"
            name="person_surname"
            id="person_surname"
            required
            [(ngModel)]="person.surname"
            titlecase
            (input)="onNameInput()"
            [disabled]="enableNameEditing == false"
            #personSurname="ngModel"
        />
        <div class="input-errors" [ngxErrors]="personSurname">
            <label for="person_surname" ngxError="required" class="input-error">
                Please enter the surname.
            </label>
        </div>
    </li>

    <li class="form__item">
        <label class="input-label" for="person_date_of_birth"
            >Date of Birth</label
        >
        <custom-date-time-picker
            name="person_date_of_birth"
            id="person_date_of_birth"
            [(ngModel)]="person.date_of_birth"
            required
            hideTimePicker="true"
            #personDateOfBirth="ngModel"
        ></custom-date-time-picker>
        <div class="input-errors" [ngxErrors]="personDateOfBirth">
            <label
                for="person_date_of_birth"
                ngxError="required"
                class="input-error"
            >
                Please enter a valid birth date.
            </label>
        </div>
    </li>

    <li class="form__item">
        <label class="input-label">Occupation Status</label>
        <label class="input-checkbox">
            <input
                type="radio"
                [value]="occupationStatuses.Employed"
                name="person_occupation_status"
                id="person_occupation_status-employed"
                [(ngModel)]="person.occupation_status"
            />
            <span class="input-checkbox__label">Employed</span>
        </label>

        <label class="input-checkbox">
            <input
                type="radio"
                [value]="occupationStatuses.SelfEmployed"
                name="person_occupation_status"
                id="person_occupation_status-selfemployed"
                invalidValueValidation="selfemployed"
                [invalidValueValidationDisabled]="!employee"
                [(ngModel)]="person.occupation_status"
                #personOccupationStatus="ngModel"
            />
            <span class="input-checkbox__label">Self Employed</span>
        </label>

        <label class="input-checkbox" *ngIf="!employee">
            <input
                type="radio"
                [value]="occupationStatuses.Retired"
                name="person_occupation_status"
                id="person_occupation_retired"
                [(ngModel)]="person.occupation_status"
            />
            <span class="input-checkbox__label">Retired</span>
        </label>

        <label class="input-checkbox" *ngIf="!employee">
            <input
                type="radio"
                [value]="occupationStatuses.Unemployed"
                name="person_occupation_status"
                id="person_occupation_status-unemployed"
                [(ngModel)]="person.occupation_status"
                (click)="person.occupation = null"
            />
            <span class="input-checkbox__label">Unemployed</span>
        </label>

        <div class="input-errors" [ngxErrors]="personOccupationStatus">
            <label
                for="company_registered_type"
                ngxError="invalidValue"
                class="input-error"
            >
                STOP! if the person is acting as a non-registired business,
                you're in the
                <a class="link" href="#/customers/people/add">wrong place</a>
            </label>
        </div>
    </li>

    <li
        class="form__item"
        *ngIf="person.occupation_status != occupationStatuses.Unemployed"
    >
        <label class="input-label" for="person_occupation">
            {{
                person.occupation_status == occupationStatuses.Employed ||
                person.occupation_status == occupationStatuses.SelfEmployed
                    ? 'Occupation Role'
                    : 'Previous Occupation Role'
            }}
        </label>
        <input
            type="text"
            name="person_occupation"
            id="person_occupation"
            required
            [(ngModel)]="person.occupation"
            (ngModelChange)="updateOccupation($event)"
            [matAutocomplete]="auto"
            #personOccupation="ngModel"
        />

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                *ngFor="let filteredJob of filteredJobs | async"
                [value]="filteredJob.title"
                >{{ filteredJob.title }}</mat-option
            >
        </mat-autocomplete>

        <div class="input-errors" [ngxErrors]="personOccupation">
            <label
                for="person_occupation"
                ngxError="required"
                class="input-error"
            >
                Please enter a valid job title.
            </label>
        </div>
    </li>

    <li
        class="form__item"
        *ngIf="person.occupation_status == occupationStatuses.SelfEmployed"
    >
        <label class="input-label" for="person_doing_business_as"
            >Trading Style
            <small>Doing business as</small>
        </label>
        <input
            type="text"
            name="person_doing_business_as"
            id="person_doing_business_as"
            [(ngModel)]="person.doing_business_as"
            titlecase
        />
    </li>

    <li class="form__item">
        <label class="input-label" for="person_licence_number"
            >Licence Details
            <span class="input-optional">Optional</span>
        </label>

        <div class="input-group">
            <div class="input-group__prepend">
                <div class="input-select input-group__select">
                    <mat-select
                        id="person_licence_type"
                        name="person_licence_type"
                        [(ngModel)]="person.licence_type"
                        [required]="!!person.licence_number"
                        #personLicenceType="ngModel"
                    >
                        <mat-option></mat-option>
                        <mat-option value="uk">UK</mat-option>
                        <mat-option value="nirl">Northern Ireland</mat-option>
                        <mat-option value="foreign">Foreign</mat-option>
                    </mat-select>
                </div>
            </div>

            <input
                type="text"
                name="person_licence_number"
                id="person_licence_number"
                class="input-group__input"
                uppercase
                maxlength="50"
                [(ngModel)]="person.licence_number"
                [required]="!!person.licence_type"
                #personLicenceNumber="ngModel"
            />
        </div>

        <div class="input-errors" [ngxErrors]="personLicenceNumber">
            <label
                for="person_licence_number"
                ngxError="required"
                class="input-error"
            >
                Please enter a valid license number.
            </label>
        </div>

        <div class="input-errors" [ngxErrors]="personLicenceType">
            <label
                for="person_licence_number"
                ngxError="required"
                class="input-error"
            >
                Please enter a valid license type.
            </label>
        </div>
    </li>

    <li class="form__item">
        <label class="input-label" for="person_licence_date"
            >Licence Earned Date
            <span class="input-optional">Optional</span>
        </label>
        <custom-date-time-picker
            name="person_licence_date"
            id="person_licence_date"
            [(ngModel)]="person.licence_date"
            hideTimePicker="true"
        ></custom-date-time-picker>
    </li>

    <li class="form__item">
        <label class="input-label" for="person_passport_number"
            >Passport Number
            <span class="input-optional">Optional</span>
        </label>
        <input
            type="text"
            name="person_passport_number"
            id="person_passport_number"
            maxlength="50"
            [(ngModel)]="person.passport_number"
        />
    </li>
</ul>
